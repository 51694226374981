import { computed, ref } from 'vue'
import parametrosListarService from '../../../../services/parametrosListarService'
import parametrosActualizarService from '../../../../services/parametrosActualizarService'
import { messageSuccess, messageWarning } from '../../../../../../../libs/mensajes'
import { number, object } from 'yup'

const useGeneral = () => {
  const _model = ref({
    diaApertura: null,
    diaCierre: null
  })

  const getModel = computed(() => {
    return _model.value
  })

  const schemaItem = object().shape({
    diaApertura: number().min(0).max(31).required().label('dia de apertura'),
    diaCierre: number().min(0).max(31).required().label('dia de cierre')
  })

  const _handleCatch = (err) => {
    console.error(err.message)
  }
  const _modelFill = (values) => {
    for (const [key, value] of Object.entries(values)) {
      _model.value[key] = value
    }
  }
  const fetchConfig = () => {
    return parametrosListarService().then(({ data }) => {
      _modelFill(data)
    }).catch(_handleCatch)
  }

  const onUpdate = async () => {
    try {
      if (getModel.value.diaApertura > getModel.value.diaCierre) {
        messageWarning('El dia de apertura no puede ser mayor que el el cierre. No se guardaran los cambios.')
        return false
      }
      const payload = await schemaItem.validate(getModel.value)
      const { data } = await parametrosActualizarService(payload)
      messageSuccess(data.message)
      fetchConfig()
    } catch (error) {
      messageWarning(error)
    }
  }

  return {
    getModel,
    fetchConfig,
    onUpdate
  }
}

export default useGeneral
