<template>
  <section class="my-5">
    <div class="flex items-center">
      <back-button class="mr-2"/>
      <div class="font-bold text-xl">Ajustes generales </div>
    </div>
    <div class="w-full my-5 box p-4">
      <form >
        <div class="mb-2 flex flex-col lg:flex-row lg:items-center">
          <label
          for="diaInicio"
          class="w-1/3"
          >
            Dia de Inicio
          </label>
          <input
          id="diaInicio"
          v-model="getModel.diaApertura"
          type="number"
          min="1"
          max="31"
          class="form-control"
          placeholder="Minimo 1 maximo 31"
          >
        </div>
        <div class="mb-2 flex flex-col lg:flex-row lg:items-center">
          <label
          for="diaCierre"
          class="w-1/3"
          >
            Dia de cierre
          </label>
          <input
          id="diaCierre"
          v-model="getModel.diaCierre"
          type="number"
          min="1"
          max="31"
          class="form-control"
          placeholder="Minimo 1 maximo 31"
          >
        </div>
      </form>
    </div>
    <div class="w-full">
      <button
      type="button"
      class="btn btn-primary"
      @click="onUpdate"
      >
        Guardar
      </button>
    </div>
  </section>
</template>

<script>
import useGeneral from './useGeneral'
import { onMounted } from 'vue'
export default {
  name: 'General',
  setup () {
    const {
      getModel,
      fetchConfig,
      onUpdate
    } = useGeneral()
    onMounted(fetchConfig)
    return {
      getModel,
      onUpdate
    }
  }
}
</script>
